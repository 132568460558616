<template>
  <div class="home pt-3 pt-md-0">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mt-md-0 mt-lg-0"
    > -->
    <NavbarOne
      class="card-top d-md-none d-lg-none"
      id="card-top"
      :showNavbar="showNavbar"
      :showCart="showCart"
      :showCartDesktop="showCartDesktop"
      :showSearch="showSearch"
      :showLogo="showLogo"
    >
    </NavbarOne>
    <!-- </section> -->

    <!-- <div class="maps-box">


      <GmapMap
        ref="mapRef"
        :center="mapCenter"
        :zoom="mapZoom"
        map-type-id="terrain"
        :options="mapsOptions"
        style="width: 100%; height: 100%; position: relative; z-index: 0"
      >
        <GmapMarker
          ref="markerBranch"
          :icon="{ url: '/markerBorder30x30.png' }"
          :position="
            google &&
            new google.maps.LatLng(
              branch.longlat == null ? null : splitLatLng(branch.longlat).lat,
              branch.longlat == null ? null : splitLatLng(branch.longlat).lng
            )
          "
          :visible="branch.longlat !== null"
          v-for="(branch, index) of listBranch"
          :key="index"
          :clickable="true"
          @click="openWindow(branch)"
        />
        <GmapMarker
          ref="markerMyLocation"
          v-if="myLatLng != null"
          :icon="{ url: '/markerMe20x20.png' }"
          :position="
            google && new google.maps.LatLng(myLatLng.lat, myLatLng.lng)
          "
        />
        <gmap-info-window
          @closeclick="window_open = false"
          :opened="window_open"
          :position="infowindow"
          :options="{
            pixelOffset: {
              width: 0,
              height: -35,
            },
          }"
        >
          <heading-branch
            v-if="branchSelected != null"
            :name="branchSelected.name"
            :address="branchSelected.address"
            @click="$router.push(`/patient/branch/${branchSelected.id}`)"
          />
        </gmap-info-window>
      </GmapMap>
    </div>
    <div class="nearme">
      <SearchBranch
        :list-branch="listBranchSearch"
        @filterBranch="key => filterBranch(key)"
        :nearme="isNearme"
        @search="key => searchBranch(key)"
      />
    </div> -->
    <v-card class="card-top-filter ma-3 mt-16 ma-md-5">
      <div class="section bg-section">
        <!-- <v-list-item three-line> -->
        <v-list-item>
          <v-list-item-content class="list-item-content">
            <v-container fluid class="filter">
              <v-row class="filter-row-home-patient">
                <!-- <v-col cols="12" md="4" sm="3" class="service-filter"> -->
                  <!-- <v-container fluid> -->
                  <!-- <v-select
                    prepend-inner-icon="mdi-stethoscope"
                    v-model="payload.selectedService"
                    :items="services"
                    item-text="name"
                    item-value="id"
                    :label="$t('field.type_of_test')"
                    multiple
                    color="#00A4B3"
                    class="service inp-text service-fit-content"
                    dense
                    solo
                  >
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index < 1">
                        <template
                          v-if="
                            item[`sub_category_name_${$i18n.locale}`].length >
                            25
                          "
                        >
                          {{
                            item[`sub_category_name_${$i18n.locale}`].substring(
                              0,
                              25
                            )
                          }}...
                        </template>
                        <template v-else>
                          {{ item[`sub_category_name_${$i18n.locale}`] }}
                        </template>
                      </span>
                      <span v-if="index === 1" class="grey--text text-caption"
                        >&nbsp; (+{{
                          payload.selectedService.length - 1
                        }}
                        Item)</span
                      >
                    </template>
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @mousedown.prevent
                        @click="toggle"
                        class="service-list"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="
                              payload.selectedService.length > 0
                                ? 'indigo darken-4'
                                : ''
                            "
                          >
                            {{
                              payload.selectedService.length == 0
                                ? "mdi-checkbox-blank-outline"
                                : "mdi-close-box"
                            }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="content content-service">
                            {{
                              chooseSomeService ||
                              payload.selectedService.length > 0
                                ? "Unselect all"
                                : $t("select_all")
                            }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>

                    <template v-slot:item="data">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            payload.selectedService.length == 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                          {{
                            chooseAllService || cekParam(data.item.id)
                              ? "mdi-checkbox-outline"
                              : "mdi-checkbox-blank-outline"
                          }}
                        </v-icon>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title class="content content-service">
                          {{ data.item[`sub_category_name_${$i18n.locale}`] }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select> -->
                  <!-- <v-select
                      v-model="payload.selectedService"
                      :items="services"
                      item-text="name"
                      item-value="id"
                      :label="$t('field.type_of_test')"
                      multiple
                      prepend-icon="mdi-magnify"
                      color="#00A4B3"
                      class="service"
                    >
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index < 1">
                        <template v-if="item[`name_${$i18n.locale}`].length > 25">
                          {{ item[`name_${$i18n.locale}`].substring(0, 25) }}...
                        </template>
                        <template v-else>
                          {{ item[`name_${$i18n.locale}`] }} 
                        </template>
                      </span>
                      <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                        >&nbsp; (+{{
                          payload.selectedService.length - 1
                        }}
                        Item)</span
                      >
                    </template>
                      
                      <template v-slot:prepend-item>
                        <v-list-item ripple @mousedown.prevent @click="toggle">
                          <v-list-item-content>
                            <v-list-item-title class="content">
                              {{
                                chooseSomeService ||
                                payload.selectedService.length > 0
                                  ? "Unselect all"
                                  : $t("select_all")
                              }}
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon
                              :color="
                                payload.selectedService.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >
                              {{
                                payload.selectedService.length == 0
                                  ? "mdi-checkbox-blank-outline"
                                  : "mdi-close-box"
                              }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>

                      <template v-slot:item="data">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              payload.selectedService.length == 0
                                ? 'indigo darken-4'
                                : ''
                            "
                          >
                            {{
                              chooseAllService || cekParam(data.item.id)
                                ? "mdi-checkbox-outline"
                                : "mdi-checkbox-blank-outline"
                            }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="content">
                            {{ data.item[`name_${$i18n.locale}`] }}
                          </v-list-item-title>
                        </v-list-item-content>

                        
                      </template>
                    </v-select> -->
                  <!-- </v-container> -->
                <!-- </v-col> -->
                <v-col cols="12" md="5" sm="4" class="branch">
                  <v-autocomplete
                    class="filterTab"
                    item-text="name"
                    item-value="id"
                    :label="$t('field.state')"
                    color="#00A4B3"
                    prepend-inner-icon="mdi-home-modern"
                    :items="province"
                    v-model="payload.selectProvince"
                    ref="autocomplete"
                    :rules="[
                      v => !!v || `${$t('field.state')} ${$t('is_required')}`,
                    ]"
                    dense
                    solo
                  ></v-autocomplete>
                </v-col>
                <!-- <v-col cols="12" md="3" sm="3" class="date-filter"> -->
                <DateFilter
                  :value="payload.date"
                  @change="payload.date = $event"
                  class="date-filter col-sm-4 col-md-5 col-12 pt-3"
                />
                <!-- </v-col> -->

                <v-col cols="12" md="2" sm="4" class="column-button mx-auto">
                  <!-- style="margin-bottom: 30px" -->
                  <v-btn
                    class="button-purple d-sm-flex"
                    rounded
                    @click="searchByFilter(payload)"
                  >
                    {{ $t("search") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider color="white"></v-divider>
              <FilterNearMe
                @changeSortBy="nilai => updateSortBy(nilai)"
                :value="sortByDefaultValue"
                @clickMaps="parMaps => clickMaps(parMaps)"
                :valMaps="valueParamMaps"
              />
              <!-- @clickMapsLeaflet = "parMapsLeaflet => clickNearmeLeaflet(parMapsLeaflet)" -->
            </v-container>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-card>
    <!-- <div>
      {{listNearMe}}
    </div> -->
    <template v-if="listMapsLeaflet == false">
      <v-row class="listNearme">
        <v-col cols="12" v-for="(item, index) of listNearMe" :key="index">
          <card-branch
            :item="item"
            :category="payload.selectedService"
          ></card-branch>
        </v-col>
      </v-row>
    </template>

    <template v-if="listMapsLeaflet == true">
      <v-row class="padding-maps-leaflet">
        <v-col cols="12" lg="4" md="4">
          <card-branch
            class="mb-3 mb-md-5"
            v-for="(item, index) of listNearMe"
            :key="index"
            :item="item"
            nonResponsive
            :category="payload.selectedService"
          ></card-branch>
        </v-col>
        <v-col class="sticky-maps" cols="12" md="8" lg="8">
          <v-card>
            <div class="section">
              <l-map
                style="
                  width: 100%;
                  height: 500px;
                  position: relative;
                  z-index: 0;
                "
                class="maps-nearme"
                :zoom="mapZoom"
                :center="mapCenter"
              >
                <l-tile-layer
                  :url="url"
                  :attribution="attribution"
                ></l-tile-layer>
                <div v-for="(branch, index) of listBranch" :key="index">
                  <l-marker
                    v-if="branch.longlat != null"
                    :lat-lng="[
                      parseFloat(splitLatLng(branch.longlat).lat),
                      parseFloat(splitLatLng(branch.longlat).lng),
                    ]"
                  >
                    <l-icon>
                      <img
                        src="/markerBorder30x30.png"
                        alt=""
                        width="30"
                        style="transform: translate(-30%, -80%)"
                      />
                    </l-icon>
                    <l-popup>
                      <card-branch-popup-maps
                        :item="branch"
                        nonResponsive
                        :category="payload.selectedService"
                      ></card-branch-popup-maps>
                    </l-popup>
                  </l-marker>
                </div>
                <l-marker :lat-lng="mapCenter" v-if="myLatLng != null">
                  <l-icon>
                    <img
                      src="/markerMe20x20.png"
                      alt=""
                      width="30"
                      style="transform: translate(-50%, -50%)"
                    />
                  </l-icon>
                </l-marker>
              </l-map>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <DialogMenu
        :tampilkan="dialog"
        @toggleDialog="dialog => toggleDialogMenu(dialog)"
      />
    </template>
  </div>
</template>
<script>
// import { gmapApi } from "vue2-google-maps";
import API from "../../../../service/api_service";
import Auth from "../../../../service/auth_service";
import FilterNearMe from "@/views/v2/Patient/filter/nearme.vue";
// import CardAppointment from "@/views/v2/Patient/book_appointment/CardAppointment.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import CardBranch from "@/components/v3/cards/card-branch.vue";
import DateFilter from "@/views/v2/Patient/filter/date.vue";
import moment from "moment";
// import NearmeMap from "@/components/v3/nearme/index.vue";

import CardBranchPopupMaps from "@/components/v3/cards/card-branch-popup-maps.vue";

import DialogMenu from "@/components/v2/dialog/dialogMenu.vue";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from "vue2-leaflet";
import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    FilterNearMe,
    // CardAppointment,
    NavbarOne,
    DateFilter,
    CardBranch,
    // NearmeMap,

    CardBranchPopupMaps,
    DialogMenu,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
  },
  data: () => ({
    url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
    attribution:
      'Map & Imagery &copy; <a href="https://www.google.com/maps/">Google Maps</a>',
    showNavbar: true,
    showCart: true,
    showCartDesktop: true,
    showSearch: true,
    showLogo: false,
    mapsOptions: {
      disableDefaultUI: true,
    },
    infowindow: null,
    window_open: false,
    mapCenter: [-8.739184, 115.171127],
    mapZoom: 11,
    myLatLng: null,
    listBranch: [],
    listBranchSearch: [],
    isNearme: false,
    branchSelected: null,
    dialog: false,
    listNearMe: [],
    sortByDefaultValue: "distance",
    province: [],
    payload: {
      selectProvince: "",
      selectedService: [],
      date: moment().format("YYYY-MM-DD"),
    },
    services: [],
    modal_datePicker: false,
    datePicker: null,
    symptom: [
      { name: "Headache", id: 1 },
      { name: "Fever", id: 2 },
      { name: "Sore Throat", id: 3 },
      { name: "Cough", id: 4 },
      { name: "Eye Sore", id: 5 },
      { name: "Breathing Difficulty", id: 6 },
    ],
    listMapsLeaflet: false,
    valueParamMaps: "maps",
  }),
  watch: {
    "payload.date": function (val) {
      if (!val) {
        this.payload.date = moment().format("YYYY-MM-DD");
      }
    },
  },
  mounted() {
    this.getBranch();
    // province
    API.get(
      `${process.env.VUE_APP_API_TRUST}province/get?country_code=ID`,
      {}
    ).then(res => {
      if (res.statusCode == 200) {
        let temporary = res.results.filter(data => {
          return (
            data.name == "BALI" ||
            data.name == "JAWA BARAT" ||
            data.name == "JAWA TIMUR" ||
            data.name == "JAMBI" ||
            data.name == "KEPULAUAN RIAU" ||
            data.name == "RIAU"
          );
        });
        this.province = temporary.sort((a, b) => this.sortByName(a, b));
      }
    });

    // category
    API.get(`${process.env.VUE_APP_API_TRUST}product-subcategory/get`, {
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then(res => {
      if (res.statusCode == 200) {
        this.services = res.results;
      }
    });
    // API.get(
    //   `${process.env.VUE_APP_API_TRUST}product/get-category`,
    //   {}
    // ).then(res => {
    //   if (res.statusCode == 200) {
    //     this.services = res.results;
    //   }
    // });
  },
  computed: {
    // google: gmapApi,
    dateFormatted_datePicker: {
      get: function () {
        return this.formatDate(this.datePicker);
      },
      set: function (newValue) {
        this.datePicker = newValue;
      },
    },
    chooseAllService() {
      return this.payload.selectedService.length === this.services.length;
    },
    chooseSomeService() {
      return this.payload.selectedService.length > 0 && !this.chooseAllService;
    },
  },
  methods: {
    cekParam(value) {
      return this.payload.selectedService.indexOf(value) > -1 ? true : false;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.chooseAllService) {
          this.payload.selectedService = [];
        } else {
          if (this.chooseSomeService) {
            this.payload.selectedService = [];
          } else {
            let newVar = [];
            this.services.forEach(element => {
              newVar = [...newVar, element.id];
            });
            this.payload.selectedService = newVar;
          }
        }
      });
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date).format("DD-MMM-YYYY").toUpperCase();
    },
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    openWindow(branch) {
      this.branchSelected = branch;
      const marker = {
        lat:
          branch.longlat == null ? null : this.splitLatLng(branch.longlat).lat,
        lng:
          branch.longlat == null ? null : this.splitLatLng(branch.longlat).lng,
      };

      this.infowindow = marker;
      this.window_open = true;
    },
    // findMe() {
    //   if (navigator.geolocation) {
    //     let _this = this;
    //     navigator.geolocation.getCurrentPosition(function (position) {
    //       const { latitude, longitude } = position.coords;
    //       _this.mapCenter = { lat: latitude, lng: longitude };
    //       _this.myLatLng = _this.mapCenter;
    //       _this.mapZoom = 15;
    //       // _this.getNearMe();
    //     });
    //   }
    // },
    // filterBranch(key) {
    //   if (key == "nearme") {
    //     this.isNearme = true;
    //     this.getNearMe();
    //   } else {
    //     this.isNearme = false;
    //     this.listBranchSearch = this.listBranch;
    //   }
    // },
    // searchBranch(key) {
    //   this.listBranchSearch = this.listBranch.filter(branch => {
    //     return branch.name.toLowerCase().includes(key.toLowerCase());
    //   });
    // },

    // async getNearMe() {
    //   let lat = this.myLatLng.lat;
    //   let lng = this.myLatLng.lng;

    //   try {
    //     const response = await API.get(
    //       `${process.env.VUE_APP_API_TRUST}geo/distance-search?lat=` +
    //         lat +
    //         `&lng=` +
    //         lng +
    //         ``,
    //       {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${Auth.getToken()}`,
    //       },
    //       this.myLatLng
    //     );
    //     this.listNearMe = response.results;
    //     this.listNearMe.sort(function (x, y) {
    //       return (
    //         x.distance[0].elements[0].distance.value -
    //         y.distance[0].elements[0].distance.value
    //       );
    //     });
    //     this.isNearme = true;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    rad(x) {
      return (x * Math.PI) / 180;
    },
    getDistance(p1, p2) {
      // console.log(p1,p2)
      var R = 6378137; // Earth’s mean radius in meter
      var dLat = this.rad(p2.lat - p1[0]);
      var dLong = this.rad(p2.lng - p1[1]);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.rad(p1[0])) *
          Math.cos(this.rad(p2.lat)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d; // returns the distance in meter
    },

    async getNearMeByCity() {
      let lat = this.myLatLng.lat;
      let lng = this.myLatLng.lng;

      try {
        const response = await API.get(
          `${process.env.VUE_APP_API_TRUST}geo/distance-search?lat=` +
            lat +
            `&lng=` +
            lng +
            ``,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          this.myLatLng
        );
        this.listNearMe = response.results;
        this.isNearme = true;
      } catch (error) {
        console.log(error);
      }
    },
    async getBranch() {
      this.$store.commit("setLoading", true);
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}plant/get-lab-queue?product_subcategory_ids=${this.payload.selectedService}&province_id=${this.payload.selectProvince}&date=${this.payload.date}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        //filter bali jawabarat

        // let temporary = resp.results.filter(data=>{
        //   return data.name == "VIRTU DIGILAB BADUNG NUSA DUA" || data.name == "VIRTU DIGILAB NRC BOGOR TECHNOVILLAGE"
        // })

        this.listBranch = resp.results;
        const this_ = this;
        navigator.permissions.query({ name: "geolocation" }).then(result => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              function (position) {
                const { latitude, longitude } = position.coords;
                this_.mapCenter = [parseFloat(latitude), parseFloat(longitude)];
                this_.sortingBranch();
              },
              function () {
                this_.sortingBranch();
              }
            );
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              function (position) {
                const { latitude, longitude } = position.coords;
                this_.mapCenter = [parseFloat(latitude), parseFloat(longitude)];
                this_.sortingBranch();
              },
              function () {
                this_.sortingBranch();
              }
            );
          } else {
            navigator.geolocation.getCurrentPosition(
              function (position) {
                const { latitude, longitude } = position.coords;
                this_.mapCenter = [parseFloat(latitude), parseFloat(longitude)];
                this_.sortingBranch();
              },
              function () {
                this_.sortingBranch();
              }
            );
          }
        });
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.$store.commit("setLoading", false);
      }
    },
    sortingBranch() {
      this.listNearMe = this.listBranch;
      const _this = this;
      this.listBranch.map(data => {
        if (data.longlat != "" && data.longlat != null) {
          const getLat = parseFloat(data.longlat.split(",")[0]);
          const getLng = parseFloat(data.longlat.split(",")[1]);
          data.distance = this.getDistance(_this.mapCenter, {
            lat: getLat,
            lng: getLng,
          });
        } else {
          data.distance = 1000000000000;
        }
        return data;
      });
      this.listBranch = this.listBranch.sort((a, b) => {
        if (a.distance < b.distance) {
          return -1;
        }
        if (a.distance > b.distance) {
          return 1;
        }
        return 0;
      });

      this.listNearMe = this.listBranch;
    },
    splitLatLng(latlng) {
      const latlngArr = latlng.split(",");
      return {
        lat: parseFloat(latlngArr[0]),
        lng: parseFloat(latlngArr[1]),
      };
    },
    async openDialog() {
      this.dialog = true;
    },
    toggleDialogMenu(toggle) {
      this.dialog = toggle;
    },

    updateSortBy(value) {
      this.sortByDefaultValue = value;

      if (value == "distance") {
        this.getBranch();
      }
      if (value == "city") {
        this.getNearMeByCity();
      }
      if (value == "price") {
        this.getNearMeByCity();
      }
    },
    clickMaps(val) {
      if (val == "maps") {
        this.listMapsLeaflet = true;
        this.valueParamMaps = "listMaps";
      } else {
        this.listMapsLeaflet = false;
        this.valueParamMaps = "maps";
      }
    },

    async searchByFilter(value) {
      if (value.selectProvince == "") {
        event.preventDefault();
        this.$swal({
          icon: "error",
          text: "Province can`t empty.",
        }).then(() => {
          this.$refs.autocomplete.focus();
        });
      } else {
        this.getBranch()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.column-icon {
  max-width: 4% !important;
}
.row-footer {
  margin-left: -7px;
}
.column-desc {
  margin-top: 4px;
  font-weight: bold;
}
.column-currency {
  margin-top: 4px;
}

.column-sort {
  margin-right: 20px;
  margin-left: -25px;
  margin-top: 4px;
}

// .list-item-content {
//   margin-top: -55px;
//   margin-bottom: -45px;
// }

.icon {
  margin-right: 10px;
}
.text {
  font-size: 12px;
  // margin-right: 50px;
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.text-sort {
  font-size: 12px;
  // margin-left: -90px;
}
.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
}

.card-top-2 {
  height: auto;
  width: auto;
}

.card-cart {
  width: auto;
  height: 60px;
}

.card-top-filter {
  height: auto;
  width: auto;
  margin-bottom: 12px !important;
}
.header {
  margin-bottom: 11px;
  margin-top: 2px;
}

.tagline {
  font-size: 20px;
  font-weight: bold;
  color: #1f3c87;
}

.description {
  font-size: 15px;
  color: black;
  font-weight: 200;
  margin-top: 10px;
}

.card {
  border-radius: 10px;
  width: 100%;
}
.branch {
  padding-left: 0;
}

// .branch {
//   margin-left: -17px;
//   margin-top: 30px;
// }

// .service-filter {
//   margin-top: 14px;
//   margin-left: -7px;
//   padding: 0;
// }

// .date-filter {
//   margin-top: 14px;
//   margin-left: 14px;
// }
.date-filter {
  padding: 0;
}
.filter {
  padding: 16px 5px 0 5px;
}

// .filter-row-home-patient {
//   margin-top: 5px;
//   margin-bottom: -30px;
// }
.filter-row-home-patient {
  height: 70px;
}

.filterTab {
  text-transform: capitalize;
  color: #1f3c87;
  font-weight: bold;
  padding-top: 0px;
  margin-top: 0px;
}

.row-filter {
  margin-top: 1px;
  flex-wrap: nowrap;
  margin-left: 545px;
  .text-filter {
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
    min-width: 200px;
  }
}

.column-button {
  // margin-top: 10px;
  // margin-left: 10px;
  padding-right: 2px;
  //margin-top: 6px;
}

.button {
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  color: white;
  background-color: #00a4b3 !important;
  border-radius: 4px;
  width: 100%;
  min-width: 130px;
  padding: 0 16px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: 25px;
}
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: transparent;
  margin-left: -20px !important;
  margin-top: -2px;
}
.column-select-sort {
  margin-left: -195px;
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none;
    margin-left: -20px !important;
    margin-top: -2px;
  }
  .v-select.v-input--dense .v-select__selection--comma {
    margin: 5px 4px 3px 0;
    font-size: 12px;
  }
  .v-text-field.v-text-field--enclosed {
    padding: 0;
    margin-top: -9px;
  }
  .v-application p {
    margin-bottom: unset !important;
  }
}
.select-sort {
  font-size: 12px;
  text-transform: capitalize;
  // margin-left: -8px;
}
.column-icon-filter {
  margin-right: 10px;
  margin-left: 10px;
}
.v-input__control {
  margin-left: -20px !important;
  margin-top: -2px;
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px !important;
}
.v-label {
  font-size: 12px;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 35px;
  min-width: 130px;
  padding: 0 16px;
}

.v-card__actions {
  align-items: center;
  display: flex;
  padding: 8px;
  margin-bottom: 10px;
  margin-top: -30px;
}

.v-select__selections {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
  font-size: 12px;
  font-weight: 200;
}

.v-autocomplete.v-select.v-input input {
  min-width: 64px;
  font-weight: 200;
  font-size: 12px;
}

.cart-right {
  text-align: right;
  justify-content: flex-end;
  background: none;
  width: 95%;
  float: right;
}

.text-field {
  max-width: 70%;
  margin-top: 6px;
  margin-left: 10px;
}

.v-select__selections {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
  font-size: 12px;
  text-transform: capitalize;
}

.content {
  color: #1f3c87;
  font-weight: bold;
  font-size: 12px;
}
.service {
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.listNearme {
  margin: unset !important;
  padding: 0 10px;
}

.padding-maps-leaflet {
  margin: unset !important;
  padding: 0 10px;
}

.sticky-maps {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  height: calc(95vh);
  overflow-y: auto;
}

@media only screen and (max-width: 600px) {
  // .list-item-content {
  //   margin-top: -20px;
  //   margin-bottom: -45px;
  // }
  .home {
    overflow-x: hidden;
    // min-height: 100vh;
    // margin-bottom: -19vh;
  }

  .cart {
    margin-right: 3px;
    z-index: 1;
    margin-right: 57px;
    margin-left: 2px;
  }
  .text-field {
    max-width: 75%;
    margin-left: 9px !important;
    margin-top: 6px;
  }

  .notification {
    height: 55px;
    border-radius: 3px;
    margin-top: 5px;
    margin-left: -14px;
  }

  .user-card {
    max-width: 56% !important;
    margin-top: -41px;
    margin-left: 154px;
  }
  .bell-mobile {
    margin-left: -95px;
  }

  .card-cart {
    position: fixed !important;
    top: 0 !important;
    z-index: 1000 !important;
    height: 60px;
    width: 100% !important;
  }

  .card-top-filter {
    margin-top: 12px;
  }
  .column-select-sort {
    margin-left: -175px;
    margin-top: 0px;
  }
  .column-button {
    margin-top: 0px;
  }
  // .mx-auto {
  //   margin-top: 10px;
  // }
  // .service-filter {
  //   margin-top: -20px;
  //   margin-left: -5px;
  // }
  // .date-filter {
  //   margin-top: -15px;
  //   margin-left: -5px;
  // }
  // .branch {
  //   margin-left: -5px;
  //   margin-top: 0px;
  // }

  // .button {
  //   margin-bottom: 10px;
  //   margin-top: -45px !important;
  // }

  .title {
    margin-top: 20px;
  }

  .image {
    width: 10px !important;
  }

  .column-desc {
    margin-right: 55px;
  }

  .column-sort {
    margin-right: 45px;
  }
  .filter-row-home-patient {
    height: auto;
    margin-bottom: 20px;
  }
  .date-filter {
    margin-left: 0;
  }
  .date-filter-component {
    margin: 12px;
    padding: 0 0 0 12px;
  }
  .card-top-filter .section .v-list-item {
    padding: 14px;
  }
  .branch {
    padding: 12px;
  }
}

@media only screen and (max-width: 1023px) {
  .card-top-filter {
    margin: 80px 20px 14px 20px !important;
  }
}
</style>
<style>
.date-filter .date-filter-component .date {
  margin-top: 0;
}
/* .filter-row-home-patient .theme--light.v-icon {
  color: #1f3c87;
} */

.filter-row-home-patient .v-select__selections {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 200;
}
.filter-row-home-patient .v-text-field input {
  font-size: 12px;
}
.filter-row-home-patient
  .v-autocomplete.v-input
  > .v-input__control
  > .v-input__slot {
  font-size: 12px;
  font-weight: 200;
}

.footer-filter
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none;
}

.footer-filter .v-text-field.v-text-field--solo .v-input__append-inner,
.v-text-field.v-text-field--solo .v-input__prepend-inner {
  align-self: center;
  margin-top: 0;
  /* margin-left: -170px; */
}

.footer-filter .v-select__slot {
  width: 110px;
}

.footer-filter p {
  margin-bottom: unset !important;
}
.footer-filter .v-text-field__details {
  display: none;
}
.bg-section {
  background: #00a4b3;
}
.button-purple {
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  color: white !important;
  background-color: #1f3c87 !important;
  border-radius: 4px;
  width: 100%;
  min-width: 130px;
  padding: 0 16px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: 35px;
}
.service-list,
.v-list--dense .v-list-item {
  min-height: 30px !important;
}

.service-list,
.v-list-item__action {
  margin: 0px !important;
  margin-right: 10px !important;
}
</style>
