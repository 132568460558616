<template>
  <div class="footer-filter">
    <v-row class="mt-1 row-footer">
      <v-col cols="12" md="12">
        <div class="d-flex justify-space-between">
          <div class="d-flex align-center">
            <icons icon="pin-location" color="white" />
            <p
              style="color: white"
              class="text"
              @click="$emit('clickMaps', valMaps)"
              v-if="this.valMaps == 'maps'"
            >
              {{ $t("page.nearme.map_view") }}
            </p>
            <p
              style="color: white"
              class="text"
              @click="$emit('clickMaps', valMaps)"
              v-if="this.valMaps == 'listMaps'"
            >
              {{ $t("page.nearme.list_view") }}
            </p>
          </div>
          <div class="d-flex align-center">
            <p class="text-sort">{{ $t("page.nearme.sort_by") }}</p>
            <v-select
              style="margin-left: 5px !important"
              class="select-sort"
              :items="sort"
              item-text="text"
              item-value="value"
              v-model="nilai"
              @change="$emit('changeSortBy', nilai)"
              dense
              color="white"
            >
            </v-select>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import icons from "@/components/elements/Icons.vue";

export default {
  components: {
    icons,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    valMaps: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      defaultSelected: {
        default: "distance",
      },
      nilai: this.value,
      sort: [],
    };
  },

  created() {
    this.sort = [
      { text: this.$t("page.nearme.distance"), value: "distance" },
      // { text: this.$t("page.nearme.price"), value: "price" },
      // { text: this.$t("page.nearme.city"), value: "city" },
    ];
  },
  watch: {
    value: function (new_val, old_val) {
      if (old_val) {
        this.nilai = new_val;
      }
    },
    valMaps: function (new_val, old_val) {
      if (old_val) {
        this.valMaps = new_val;
      }
    },
  },

  methods: {
    changeSorting(event) {
      console.log(event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.column-icon {
  max-width: 4% !important;
}

.row-footer {
  margin-left: -7px;
}
.column-desc {
  margin-top: 4px;
  font-weight: bold;
}

.column-currency {
  margin-top: 4px;
}

.column-sort {
  margin-right: 20px;
  margin-left: -25px;
  margin-top: 4px;
}

.list-item-content {
  margin-top: -55px;
  margin-bottom: -45px;
}
.icon {
  margin-right: 10px;
}
.text {
  font-size: 12px;
  // margin-right: 50px;
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.card {
  border-radius: 10px;
  width: 100%;
}
.text-sort {
  font-size: 12px;
  color: white;
}

.branch {
  margin-left: -17px;
  margin-top: 10px;
}

.service-filter {
  margin-top: 14px;
  margin-left: -7px;
  padding: 0;
}

.date-filter {
  margin-top: 14px;
  margin-left: 14px;
}

.filter-row-home-patient {
  margin-top: 5px;
  margin-bottom: -30px;
}

.filterTab {
  text-transform: capitalize;
  color: #1f3c87;
  font-weight: bold;
  padding-top: 0px;
  margin-top: 0px;
}

.row-filter {
  margin-top: 1px;
  flex-wrap: nowrap;
  margin-left: 545px;
  .text-filter {
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
    min-width: 200px;
  }
}

.column-button {
  margin-top: 10px;
  margin-left: 10px;
  padding-right: 2px;
}

.button {
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  color: white;
  background-color: #00a4b3 !important;
  border-radius: 4px;
  width: 100%;
  min-width: 130px;
  padding: 0 16px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: 25px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 130px;
  padding: 0 16px;
}

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: transparent;
  margin-left: -20px !important;
  margin-top: -2px;
}

.column-select-sort {
  margin-left: -195px;
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none;
    margin-left: -20px !important;
    margin-top: -2px;
  }
  .v-select.v-input--dense .v-select__selection--comma {
    margin: 5px 4px 3px 0;
    font-size: 12px;
  }
  .v-text-field.v-text-field--enclosed {
    padding: 0;
    margin-top: -9px;
  }
  .v-application p {
    margin-bottom: 21px !important;
  }
}

.select-sort {
  font-size: 12px;
  text-transform: capitalize;
  // margin-left: -8px;
  background: transparent !important;
}

.column-icon-filter {
  margin-right: 10px;
  margin-left: 10px;
}

.v-input__control {
  margin-left: -20px !important;
  margin-top: -2px;
}

@media screen and (max-width: 600px) {
  .column-select-sort {
    margin-left: -175px;
    margin-top: 0px;
  }
  .mx-auto {
    margin-top: 10px;
  }
  .service-filter {
    margin-top: -20px;
    margin-left: -5px;
  }
  .date-filter {
    margin-top: -15px;
    margin-left: -5px;
  }
  .branch {
    margin-left: -5px;
  }

  .button {
    margin-bottom: 10px;
    margin-top: -45px !important;
  }

  .title {
    margin-top: 20px;
  }

  .image {
    width: 10px !important;
  }

  .column-desc {
    margin-right: 55px;
  }

  .column-sort {
    margin-right: 45px;
  }
}
</style>
<style lang="scss">
// .filter-row-home-patient .theme--light.v-icon {
//   color: #1f3c87;
// }

.filter-row-home-patient .v-select__selections {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 200;
}
.filter-row-home-patient .v-text-field input {
  font-size: 12px;
}
.filter-row-home-patient
  .v-autocomplete.v-input
  > .v-input__control
  > .v-input__slot {
  font-size: 12px;
  font-weight: 200;
}

.footer-filter
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none;
}

.footer-filter .v-text-field.v-text-field--solo .v-input__append-inner,
.v-text-field.v-text-field--solo .v-input__prepend-inner {
  align-self: center;
  margin-top: 0;
  /* margin-left: -70px; */
}

@media screen and (max-width: 600px) {
  .footer-filter .v-text-field.v-text-field--solo .v-input__append-inner,
  .v-text-field.v-text-field--solo .v-input__prepend-inner {
    align-self: center;
    margin-top: 0;
    /* margin-left: -50px; */
  }
}

.select-sort.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: unset !important;
}
.select-sort > .v-icon.v-icon {
  color: white;
}
.v-select.select-sort .v-select__selection--comma {
  color: white !important;
  margin-top: 2px;
}
.select-sort .theme--light.v-icon {
  color: white !important;
}

</style>
